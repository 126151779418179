.input-text {
  height: 50px !important;
  border-radius: 10px !important;
  border: solid 1px #707070 !important;
  padding: 15px !important;
  border-radius: 10px !important;
  font-size: 15px !important;
  font-family: Nunito;
  color: #707070 !important;
}

::placeholder {
  color: #707070 !important;
  font-family: Nunito !important;
  opacity: 1 !important;
  /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #707070 !important;
  font-family: Nunito !important;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #707070 !important;
  font-family: Nunito !important;
}

.ant-form-item-control-input {
  width: 100% !important;
}

.ant-form-item-label > label {
  font-family: Nunito;
  font-size: 14px;
  color: #000;
}

.input-text:hover {
  border-color: #707070;
  border-right-width: 1px !important;
}

.input-text:focus {
  outline: none !important;
  box-shadow: none !important;
  border: solid 1px #707070 !important;
}

.error-message {
  font-size: 14px;
  color: #ff0000;
  font-family: Nunito;
}

.error-border {
  border: solid 1px #ff0000 !important;
}

.form-input {
  font-size: 12px !important;
  font-family: Nunito;
}

.ant-form-item-label
  > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  display: none !important;
}

.ant-form-item-label
  > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {
  display: inline-block !important;
  margin-right: 4px !important;
  color: #ff4d4f !important;
  font-size: 14px !important;
  font-family: SimSun, sans-serif;
  line-height: 1 !important;
  content: '*';
}
