.shape-title {
  font-family: Nunito !important;
  font-size: 15px !important;
  display: block !important;
  position: absolute;
  bottom: 9px;
  right: 50%;
  transform: translateX(50%);
  z-index: 1000000;
}
